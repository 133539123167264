























import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import SharedGalleryHeader from "@/components/Shared/Gallery/Header.vue";
import SharedGalleryList from "@/components/Shared/Gallery/List.vue";
import { toDisplayName } from "@/helpers/Formatter";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseWrapper,
        SharedGalleryList,
        SharedGalleryHeader,
    },
    metaInfo() {
        const me = this.$store.state.users.me;
        const page = "Galeri - ";
        const title = page + toDisplayName(me);

        return {
            title: title,
        };
    },
})
export default class AppGalleries extends Vue {
    public searchValue: string = "";
    public isLoading: boolean = false;

    get me() {
        return this.$store.state.users.me;
    }

    get galleryByUserId() {
        return this.$store.state.galleries.galleryByUserId;
    }

    async mounted() {
        if (!this.me) return;

        this.isLoading = true;
        const userId: string = this.me.id;

        const gallery = await this.$store.dispatch(
            "galleries/getGalleryByUserId",
            userId
        );

        setTimeout(() => {
            this.isLoading = false;
        }, 200);
    }
}
